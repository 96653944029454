import React, { createContext, useReducer, useContext } from "react";
import { newBanners } from "../assets/data/banners.json";

import { version } from "../../package.json";

//Define the context
``;
const globalReducer = (state, action) => {
    switch (action.type) {
        case "SET_BANNER": {
            return {
                ...state,
                selectedBanner: action.payload,
                bannerNumber: action.payload,
            };
        }
        case "SET_CURRENTBANNERS": {
            return { ...state, currentBanners: action.payload };
        }
        case "SET_GACHADATA": {
            return { ...state, gachaData: action.payload };
        }
        case "SET_CLIP": {
            return { ...state, playClip: action.payload };
        }
        case "SET_DISPLAY": {
            return { ...state, display: action.payload };
        }
        case "SET_WISHALERT": {
            return { ...state, wishAlert: action.payload };
        }
        case "SET_AUDIO": {
            return { ...state, audio: action.payload };
        }
        case "APPLOADING": {
            return { ...state, appLoading: true };
        }
        case "STOP_APPLOADING": {
            return { ...state, appLoading: false };
        }
        case "SET_VOLUME": {
            return { ...state, volume: action.payload };
        }
        case "SET_BACKGROUNDMUSIC": {
            return { ...state, playBGM: action.payload };
        }
        case "LOADING": {
            return { ...state, loading: true };
        }
        case "STOP_LOADING": {
            return { ...state, loading: false };
        }
        case "SET_PASTBANNER": {
            return { ...state, isPastBanner: action.payload };
        }
        case "SET_UNLIMITED": {
            return { ...state, isUnlimited: action.payload };
        }
        // case "SET_ALERT": {
        //     return { ...state, alert: action.payload };
        // }
        case "SET_ACCESS": {
            return { ...state, hasAccess: action.payload };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const initialState = {
    bannerNumber: 0,
    selectedBanner: 0,
    currentBanners: newBanners,
    gachaData: [],
    playClip: false,
    display: false,
    audio: null,
    wishAlert: false,
    appLoading: true,
    volume: { masterVolume: 0.5, backgroundMusic: 0.3 },
    playBGM: true,
    loading: false,
    isPastBanner: false,
    isUnlimited: false,
    hasAccess: false,
    ilhani: "hellomiwo",
};

const GlobalStateContext = createContext(initialState);
const GlobalDispatchContext = createContext();

function objectsHaveSameKeys(...objects) {
    const allKeys = objects.reduce(
        (keys, object) => keys.concat(Object.keys(object)),
        []
    );
    const union = new Set(allKeys);
    return objects.every((object) => union.size === Object.keys(object).length);
}

const loadData = () => {
    const data = JSON.parse(localStorage.getItem("globalData"));
    //Updates version, or else resets
    if (
        !localStorage.getItem("version") ||
        localStorage.getItem("version") !== version
    ) {
        // localStorage.clear();
        localStorage.removeItem("globalData");
        localStorage.removeItem("version");

        localStorage.setItem("version", version);
        return false;
    }

    if (!data || !initialState) {
        return false;
    } else if (!objectsHaveSameKeys(data, initialState)) {
        return false;
    }
    return data;
};

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        globalReducer,
        loadData() || initialState
    );

    localStorage.setItem(
        "globalData",
        JSON.stringify({
            ...state,
            wishAlert: false,
            appLoading: true,
        })
    );

    return (
        <GlobalDispatchContext.Provider value={dispatch}>
            <GlobalStateContext.Provider value={state}>
                {children}
            </GlobalStateContext.Provider>
        </GlobalDispatchContext.Provider>
    );
};

export const useGlobalStateContext = () => useContext(GlobalStateContext);
export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext);
