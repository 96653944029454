import React from "react";
import { GlobalProvider } from "./src/context/globalContext";
import { DataProvider } from "./src/context/dataContext";

export const wrapRootElement = ({ element }) => {
    return (
        <GlobalProvider>
            <DataProvider>{element}</DataProvider>
        </GlobalProvider>
    );
};
